import "./App.css";
import { Routes, Route } from "react-router-dom";

import Home from "./views/Home";
import Login from "./views/Login";
import { SideBarContextProvider } from "./context/SideBarContext";

function App() {
  return (
    <SideBarContextProvider>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </SideBarContextProvider>
  );
}

export default App;
