import { useLazyQuery, useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Box,
  FormControl,
  FormLabel,
  TextField,
  Paper,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_ONE_QUE_ID,
  UPDATE_VARIATION_QUE_FORM,
  VARIATION_QUESTION,
} from "../../gql/productVariations";

import CancelIcon from "@mui/icons-material/Cancel";
const VariationQuestionForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const [loadQueForm, { data }] = useLazyQuery(GET_ONE_QUE_ID);

  useEffect(() => {
    loadQueForm({ variables: { id: id } });
  }, []);

  useEffect(() => {
    if (data) {
      setValues({
        id: data?.variations_form_question_by_pk.id,
        answer_type: data.variations_form_question_by_pk.answer_type,
        question_text: data.variations_form_question_by_pk.question_text,
        fk_product_variations_id:
          data?.variations_form_question_by_pk?.fk_product_variations_id,
      });
    }
  }, [data]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [update_form] = useMutation(UPDATE_VARIATION_QUE_FORM, {
    onError: (err) => {
      setLoading(false);
      alert("Error on Server");
      console.log("error or server", err);
    },
    onCompleted: (result) => {
      alert("New Form has been Updated");
    },
    refetchQueries: [VARIATION_QUESTION],
  });

  const handleUpdate = async () => {
    let isError = false;
    const errorObject = {};
    console.log("vaues ...", values);
    if (!values.question_text) {
      isError = true;
      errorObject.question_text = "Question text is required";
    }
    if (!values.answer_type) {
      isError = true;
      errorObject.answer_type = "Answer Type is required";
    }

    if (isError) {
      setErrors(errorObject);
      console.log("error ", errorObject);
      setLoading(false);
      return;
    }

    await update_form({
      variables: {
        ...values,
      },
    });
    navigate(-1);
  };

  return (
    <>
      <Card>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            m: "1rem",
          }}
        >
          <Button color="warning" onClick={() => navigate(-1)}>
            <CancelIcon sx={{ width: "50px", height: "50px" }} />
          </Button>
        </Box>

        <Paper
          sx={{
            display: "grid",
            gap: "2rem",
            p: "2rem",
            maxWidth: "40%",
            margin: "auto",
            mt: "2rem",
          }}
        >
          {/* question text */}
          {values.question_text ? (
            <FormControl>
              <FormLabel style={{ fontWeight: "bold" }}>
                Question Text
              </FormLabel>
              <TextField
                InputProps={{ sx: { height: 50 } }}
                variant="outlined"
                id="question_text"
                placeholder="Enter Question Text"
                value={values.question_text}
                onChange={handleChange("question_text")}
                error={errors.question_text ? true : false}
                helperText={errors.question_text}
              />
            </FormControl>
          ) : null}
          {/* answer type */}
          {values.answer_type ? (
            <FormControl variant="outlined">
              <InputLabel id="brand">Answer Type</InputLabel>
              <Select
                labelId="answerType"
                value={values.answer_type}
                label="answerType"
                onChange={handleChange("answer_type")}
                error={errors.answer_type ? true : false}
              >
                <MenuItem value="radio">radio</MenuItem>
                <MenuItem value="check">check</MenuItem>
              </Select>
              {errors.answer_type && (
                <FormHelperText error>{errors.answer_type}</FormHelperText>
              )}
            </FormControl>
          ) : null}
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "center", py: "2rem" }}>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleUpdate}
          >
            Update
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
};
export default VariationQuestionForm;
