import { createContext, useState } from "react";

const SideBarContext = createContext();

const SideBarContextProvider = ({ children }) => {
  const [nav, setNav] = useState("");
  const [allOrderSearchValue, setAllOrderSearchValue] = useState("");

  return (
    <SideBarContext.Provider
      value={{
        nav,
        setNav,
        allOrderSearchValue,
        setAllOrderSearchValue,
      }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export default SideBarContext;
export { SideBarContextProvider };
