import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  Box,
  FormControl,
  FormLabel,
  TextField,
  Breadcrumbs,
  Typography,
  Paper,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ALL_VARIATIONS,
  CREATE_VARIATION_QUESTION_FORM,
  GET_ALL_VARIATIONS_ANS,
  GET_ONE_ANS_ID,
  GET_ONE_QUE_ID,
  UPDATE_VARIATION_ANS_FORM,
  UPDATE_VARIATION_QUE_FORM,
  VARIATION_BY_ID,
} from "../../gql/productVariations";

import CancelIcon from "@mui/icons-material/Cancel";
const VariationQuestionForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const [loadQueForm, { data }] = useLazyQuery(GET_ONE_ANS_ID);

  useEffect(() => {
    loadQueForm({ variables: { id: id } });
  }, []);
  console.log("data ", data);
  useEffect(() => {
    if (data) {
      console.log("data ", data);
      setValues({
        id: data?.variations_form_answer_by_pk.id,
        answer_text: data.variations_form_answer_by_pk.answer_text,

        fk_variations_form_question_id:
          data?.variations_form_answer_by_pk?.fk_variations_form_question_id,
      });
    }
  }, [data]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [update_form] = useMutation(UPDATE_VARIATION_ANS_FORM, {
    onError: (err) => {
      setLoading(false);
      alert("Error on Server");
      console.log("error or server", err);
    },
    onCompleted: (result) => {
      alert("New Form has been Updated");
    },
    refetchQueries: [GET_ALL_VARIATIONS_ANS],
  });

  const handleUpdate = async () => {
    let isError = false;
    const errorObject = {};
    console.log("vaues ...", values);
    if (!values.answer_text) {
      isError = true;
      errorObject.answer_text = "Answer text is required";
    }

    if (isError) {
      setErrors(errorObject);
      console.log("error ", errorObject);
      setLoading(false);
      return;
    }

    await update_form({
      variables: {
        ...values,
      },
    });
    navigate(-1);
  };

  return (
    <>
      <Card>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            m: "1rem",
          }}
        >
          <Button color="warning" onClick={() => navigate(-1)}>
            <CancelIcon sx={{ width: "50px", height: "50px" }} />
          </Button>
        </Box>

        <Paper
          sx={{
            display: "grid",
            gap: "2rem",
            p: "2rem",
            maxWidth: "40%",
            margin: "auto",
            mt: "2rem",
          }}
        >
          {/* answer text */}
          {values.answer_text ? (
            <FormControl>
              <FormLabel style={{ fontWeight: "bold" }}>Answer Text</FormLabel>
              <TextField
                InputProps={{ sx: { height: 50 } }}
                variant="outlined"
                id="answer_text"
                placeholder="Enter Answer Text"
                value={values.answer_text}
                onChange={handleChange("answer_text")}
                error={errors.answer_text ? true : false}
                helperText={errors.answer_text}
              />
            </FormControl>
          ) : null}
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "center", py: "2rem" }}>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleUpdate}
          >
            Update
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
};
export default VariationQuestionForm;
