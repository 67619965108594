import * as React from "react";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  DELETE_VARIATION_ANS_FORM,
  GET_ALL_VARIATIONS_ANS,
  GET_ONE_QUE_ID,
} from "../../gql/productVariations";
import {
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const ALLVariationQuestionForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState();
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data: allVariations, error } = useQuery(GET_ALL_VARIATIONS_ANS, {
    variables: { questionId: id },
    fetchPolicy: "network-only",
  });

  const { data: variationId, error: err } = useQuery(GET_ONE_QUE_ID, {
    variables: { id: id },
  });

  const [deleteQForm] = useMutation(DELETE_VARIATION_ANS_FORM, {
    onError: (err) => {
      alert("Delete Error");
    },
    onCompleted: (result) => {
      console.log("result ", result);
      alert("Successfull deleted");
    },
    refetchQueries: [GET_ALL_VARIATIONS_ANS],
  });

  const handleOpen = (row) => {
    console.log("object, ", row);
    setOpen(true);
    setIds(row.id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteQueForm = async () => {
    console.log(id);
    await deleteQForm({
      variables: { id: ids },
    });
    setOpen(false);
  };
  if (!allVariations) {
    return null;
  }
  return (
    <>
      <Box display="flex" justifyContent="space-between" paddingRight="1rem">
        <Button
          variant="contained"
          onClick={() =>
            navigate(
              `/create_ans_form/${variationId.variations_form_question_by_pk.id}`
            )
          }
        >
          Create Ans Form
        </Button>
        <Button color="warning" onClick={() => navigate(-1)}>
          <CancelIcon sx={{ width: "50px", height: "50px" }} />
        </Button>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden", mt: "1rem" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 70 }}>ID</TableCell>
                <TableCell style={{ minWidth: 70 }}>Question Text</TableCell>

                <TableCell style={{ minWidth: 170 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            {allVariations.variations_form_answer.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                      No Answer Datas
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {allVariations.variations_form_answer.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.answer_text}</TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          size="small"
                          variant="contained"
                          onClick={() =>
                            navigate(`/edit_answer_form/${row.id}`)
                          }
                        >
                          Edit
                        </Button>
                        <Button
                          color={row.disabled ? "secondary" : "error"}
                          size="small"
                          variant="contained"
                          sx={{ m: 1 }}
                          onClick={() => handleOpen(row)}
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to delete?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={deleteQueForm}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ALLVariationQuestionForm;
