import { gql } from "@apollo/client";
export const PRODUCT_VARIATION = gql`
  query MyQuery($limit: Int!, $offset: Int!, $productId: uuid!) {
    product_variations(
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }
      where: { fk_product_id: { _eq: $productId } }
    ) {
      created_at
      disabled
      fk_product_id
      id
      color
      price
      size
      sold_amount
      variation_image_url
      variation_name
    }
    product_variations_aggregate {
      aggregate {
        count
      }
    }
  }
`;

//create variation form
export const CREATE_VARIATION_QUESTION_FORM = gql`
  mutation MyMutation(
    $question_text: String!
    $answer_type: String!
    $fk_product_variations_id: uuid!
  ) {
    insert_variations_form_question_one(
      object: {
        answer_type: $answer_type
        fk_product_variations_id: $fk_product_variations_id
        question_text: $question_text
      }
    ) {
      answer_type
      created_at
      fk_product_variations_id
      id
      question_text
      updated_at
    }
  }
`;

//get Variation question id
export const GET_ONE_QUE_ID = gql`
  query MyQuery($id: uuid!) {
    variations_form_question_by_pk(id: $id) {
      id
      question_text
      fk_product_variations_id
      created_at
      answer_type
      updated_at
    }
  }
`;

//product variation id
export const VARIATION_BY_ID = gql`
  query MyQuery($id: uuid!) {
    product_variations_by_pk(id: $id) {
      id
    }
  }
`;

//get product variation id
export const PRODUCT_VARIATION_ID = gql`
  query MyQuery($variationId: uuid!) {
    product_variations_by_pk(id: $variationId) {
      id
      variations_form_questions {
        answer_type
        question_text
      }
    }
  }
`;

//get all product variation
export const VARIATION_QUESTION = gql`
  query MyQuery($productVariationId: uuid!) {
    variations_form_question(
      order_by: { updated_at: desc }
      where: { fk_product_variations_id: { _eq: $productVariationId } }
    ) {
      answer_type
      created_at
      fk_product_variations_id
      id
      question_text
      updated_at
    }
    variations_form_question_aggregate {
      aggregate {
        count
      }
    }
  }
`;

//create variation answer form
export const CREATE_VARIATION_ANS_FORM = gql`
  mutation MyMutation(
    $answer_text: String!
    $fk_variations_form_question_id: uuid!
  ) {
    insert_variations_form_answer_one(
      object: {
        answer_text: $answer_text
        fk_variations_form_question_id: $fk_variations_form_question_id
      }
    ) {
      answer_text
      fk_variations_form_question_id
      id
    }
  }
`;

//get variation question id
export const GET_ONE_ANS_ID = gql`
  query MyQuery($id: uuid!) {
    variations_form_answer_by_pk(id: $id) {
      answer_text
      created_at
      fk_variations_form_question_id
      id
      updated_at
    }
  }
`;

//get all variations answer
export const GET_ALL_VARIATIONS_ANS = gql`
  query MyQuery($questionId: uuid!) {
    variations_form_answer(
      where: { fk_variations_form_question_id: { _eq: $questionId } }
    ) {
      answer_text
      created_at
      fk_variations_form_question_id
      id
      updated_at
    }
    variations_form_answer_aggregate {
      aggregate {
        count
      }
    }
  }
`;
//delete variation Question form
export const DELETE_VARIATION_QUS_FORM = gql`
  mutation MyMutation($id: uuid!) {
    delete_variations_form_question_by_pk(id: $id) {
      id
    }
  }
`;

//delete variation Ans form
export const DELETE_VARIATION_ANS_FORM = gql`
  mutation MyMutation($id: uuid!) {
    delete_variations_form_answer_by_pk(id: $id) {
      id
    }
  }
`;
//update variation que form
export const UPDATE_VARIATION_QUE_FORM = gql`
  mutation MyMutation(
    $id: uuid!
    $answer_type: String!
    $question_text: String!
    $fk_product_variations_id: uuid!
  ) {
    update_variations_form_question_by_pk(
      pk_columns: { id: $id }
      _set: {
        answer_type: $answer_type
        question_text: $question_text
        fk_product_variations_id: $fk_product_variations_id
      }
    ) {
      answer_type
      fk_product_variations_id
      id
      question_text
      created_at
      updated_at
    }
  }
`;

//update variation ans form
export const UPDATE_VARIATION_ANS_FORM = gql`
  mutation MyMutation(
    $id: uuid!
    $answer_text: String!
    $fk_variations_form_question_id: uuid!
  ) {
    update_variations_form_answer_by_pk(
      pk_columns: { id: $id }
      _set: {
        answer_text: $answer_text
        fk_variations_form_question_id: $fk_variations_form_question_id
      }
    ) {
      answer_text
      fk_variations_form_question_id
      id
    }
  }
`;
