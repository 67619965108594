import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  DELETE_VARIATION_QUS_FORM,
  PRODUCT_VARIATION_ID,
  UPDATE_VARIATION_QUE_FORM,
  VARIATION_BY_ID,
  VARIATION_QUESTION,
} from "../../gql/productVariations";
import {
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useParams } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const ALLVariationQuestionForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [ids, setIds] = useState();
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data: variationId, error: err } = useQuery(VARIATION_BY_ID, {
    variables: { id: id },
  });
  // const { data: pvariationId, error: errs } = useQuery(PRODUCT_VARIATION_ID, {
  //   variables: { id: variationId },
  // });
  // console.log("errors ---", errs);
  // console.log("pvariotions id  ---", pvariationId);

  const { data: allVariations, error } = useQuery(VARIATION_QUESTION, {
    variables: { productVariationId: id },
    fetchPolicy: "network-only",
  });

  const [deleteQForm] = useMutation(DELETE_VARIATION_QUS_FORM, {
    onError: (err) => {
      console.log("err", err);
      alert("Delete Error................");
    },
    onCompleted: (result) => {
      alert("Successfull deleted");
    },
    refetchQueries: [VARIATION_QUESTION],
  });

  const [editQueForm] = useMutation(UPDATE_VARIATION_QUE_FORM, {
    onError: (err) => {
      console.log("error", err);
      alert("Update Error");
    },
    onCompleted: (data) => {
      alert("Successfully Updated");
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (row) => {
    setOpen(true);
    setIds(row.id);
  };

  const deleteQueForm = async () => {
    await deleteQForm({
      variables: { id: ids },
    });
    setOpen(false);
  };

  if (!allVariations) {
    return null;
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" paddingRight="1rem">
        <Button
          variant="contained"
          onClick={() =>
            navigate(
              `/create_que_form/${variationId.product_variations_by_pk.id}`
            )
          }
        >
          Create Ques Form
        </Button>
        <Button color="warning" onClick={() => navigate(-1)}>
          <CancelIcon sx={{ width: "50px", height: "50px" }} />
        </Button>
      </Box>
      <Paper sx={{ width: "100%", overflow: "hidden", mt: "1rem" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 70 }}>ID</TableCell>
                <TableCell style={{ minWidth: 70 }}>Question Text</TableCell>
                <TableCell style={{ minWidth: 50 }}>Answer Type</TableCell>

                <TableCell style={{ minWidth: 170 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            {allVariations.variations_form_question.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                      No Question Datas
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {allVariations.variations_form_question.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.question_text}</TableCell>
                      <TableCell>{row.answer_type}</TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          size="small"
                          variant="contained"
                          onClick={() =>
                            navigate(`/edit_question_form/${row.id}`)
                          }
                        >
                          Edit
                        </Button>
                        <Button
                          color={row.disabled ? "secondary" : "error"}
                          size="small"
                          variant="contained"
                          sx={{ m: 1 }}
                          onClick={() => handleOpen(row)}
                        >
                          Remove
                        </Button>

                        {row.answer_type !== "input" ? (
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            onClick={() => navigate(`/all_ans_form/${row.id}`)}
                          >
                            Answer Form
                          </Button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to delete?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={deleteQueForm}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ALLVariationQuestionForm;
