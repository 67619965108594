import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  Box,
  FormControl,
  FormLabel,
  TextField,
  Breadcrumbs,
  Typography,
  Paper,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@mui/material";

import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CREATE_VARIATION_QUESTION_FORM,
  VARIATION_BY_ID,
  VARIATION_QUESTION,
} from "../../gql/productVariations";
import ALLVariationQuestionForm from "./AllVariationsQuestionForm";
import CancelIcon from "@mui/icons-material/Cancel";
const VariationQuestionForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const [questionText, setQuestionText] = useState("");
  const [answerType, setAnswerType] = useState("");
  const [loading, setLoading] = useState(false);

  const { data: variationId, error } = useQuery(VARIATION_BY_ID, {
    variables: { id: id },
  });
  console.log("error", error);

  const [create_form] = useMutation(CREATE_VARIATION_QUESTION_FORM, {
    onError: (err) => {
      setLoading(false);
      alert("Error on Server");
      console.log("error or server", err);
    },
    onCompleted: (result) => {
      setAnswerType("");
      setQuestionText("");
      alert("New Form has been added");
    },
    refetchQueries: [VARIATION_QUESTION],
  });

  const handleCreate = async () => {
    let isError = false;
    const errorObject = {};
    if (!questionText) {
      isError = true;
      errorObject.question_text = "Question text is required";
    }
    if (!answerType) {
      isError = true;
      errorObject.answer_type = "Answer Type is required";
    }

    if (isError) {
      setErrors(errorObject);
      console.log("error ", errorObject);
      setLoading(false);
      return;
    }

    await create_form({
      variables: {
        question_text: questionText,
        answer_type: answerType,
        fk_product_variations_id: variationId?.product_variations_by_pk?.id,
      },
    });
    navigate(-1);
  };
  return (
    <>
      <Card>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            m: "1rem",
          }}
        >
          <Button color="warning" onClick={() => navigate(-1)}>
            <CancelIcon sx={{ width: "50px", height: "50px" }} />
          </Button>
        </Box>

        <Paper
          sx={{
            display: "grid",
            gap: "2rem",
            p: "2rem",
            maxWidth: "40%",
            margin: "auto",
            mt: "2rem",
          }}
        >
          {/* question text */}
          <FormControl>
            <FormLabel style={{ fontWeight: "bold" }}>Question Text</FormLabel>
            <TextField
              InputProps={{ sx: { height: 50 } }}
              variant="outlined"
              id="question_text"
              placeholder="Enter Question Text"
              value={questionText}
              onChange={(e) => setQuestionText(e.target.value)}
              error={errors.questionText ? true : false}
              helperText={errors.questionText}
            />
          </FormControl>
          {/* answer type */}
          <FormControl variant="outlined">
            <InputLabel id="brand">Answer Type</InputLabel>
            <Select
              labelId="answerType"
              value={answerType}
              label="answerType"
              onChange={(e) => setAnswerType(e.target.value)}
              error={errors.answerType ? true : false}
            >
              <MenuItem value="radio">radio</MenuItem>
              <MenuItem value="check">check</MenuItem>
              <MenuItem value="input">input</MenuItem>
            </Select>
            {errors.answerType && (
              <FormHelperText error>{errors.answerType}</FormHelperText>
            )}
          </FormControl>
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "center", py: "2rem" }}>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleCreate}
          >
            Create
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
};
export default VariationQuestionForm;
