import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Box,
  FormControl,
  FormLabel,
  TextField,
  Paper,
} from "@mui/material";

import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CREATE_VARIATION_ANS_FORM,
  GET_ALL_VARIATIONS_ANS,
  GET_ONE_QUE_ID,
} from "../../gql/productVariations";
import CancelIcon from "@mui/icons-material/Cancel";

const VariationQuestionForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const [answerText, setAnswerText] = useState("");
  const [loading, setLoading] = useState(false);

  const { data: variationId } = useQuery(GET_ONE_QUE_ID, {
    variables: { id: id },
  });

  const [create_form] = useMutation(CREATE_VARIATION_ANS_FORM, {
    onError: (err) => {
      setLoading(false);
      alert("Error on Server");
      console.log("error or server", err);
    },
    onCompleted: (result) => {
      setAnswerText("");
      alert("New Form has been added");
    },
    refetchQueries: [GET_ALL_VARIATIONS_ANS],
  });

  const handleCreate = async () => {
    let isError = false;
    const errorObject = {};
    if (!answerText) {
      isError = true;
      errorObject.answerTextext = "Answer Text is required";
    }

    if (isError) {
      setErrors(errorObject);
      console.log("error ", errorObject);
      setLoading(false);
      return;
    }

    await create_form({
      variables: {
        answer_text: answerText,
        fk_variations_form_question_id:
          variationId?.variations_form_question_by_pk?.id,
      },
    });
    navigate(-1);
  };

  return (
    <>
      <Card>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            m: "1rem",
          }}
        >
          <Button color="warning" onClick={() => navigate(-1)}>
            <CancelIcon sx={{ width: "50px", height: "50px" }} />
          </Button>
        </Box>
        <Paper
          sx={{
            display: "grid",
            gap: "2rem",
            p: "2rem",
            maxWidth: "40%",
            margin: "auto",
            mt: "2rem",
          }}
        >
          {/* question text */}
          <FormControl>
            <FormLabel style={{ fontWeight: "bold" }}>Answer Text</FormLabel>
            <TextField
              InputProps={{ sx: { height: 50 } }}
              variant="outlined"
              id="answer_text"
              placeholder="Enter Question Text"
              value={answerText}
              onChange={(e) => setAnswerText(e.target.value)}
              error={errors.answerText ? true : false}
              helperText={errors.answerText}
            />
          </FormControl>
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "center", py: "2rem" }}>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleCreate}
          >
            Create
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
};
export default VariationQuestionForm;
